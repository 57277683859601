$(function () {

    $('#eventGraphModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget),
            modal = $(this);

        $.getJSON(button.data('graph-url'), function (data) {
            modal.find('.modal-body').html(data.view);
            drawEventGraph($('#blog-event-odds-graph'), data.odds.series);
        });
    });

    $('#both-home .sl-standings-table, #both-overall .sl-standings-table, #both-away .sl-standings-table').each(function () {
        new Tablesort($(this).get(0));
    });
});
