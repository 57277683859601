(function () {
    var PINNACLE_ID = '2'

    var $tipWidget = $('#tipWidget');
    var $tipEventsList = $('#tipsEventsList');

    var betSlipVue;

    if ($tipEventsList.length) {

        var listVue = new Vue({
            el: $tipEventsList[0],
            data: {
                loaded: false,
                events: [],
                url: $tipEventsList.data('url'),
                show_extra_odds: true,
                opened_event_key: null
            },
            computed: {
                'extra_odds': function () {
                    return this.show_extra_odds;
                },
                'has_draw': function () {
                    var event = this.events[0];

                    return !(event && !event.odds_draw);
                },
                'matchResultTitle': function () {
                    if (!this.has_draw) {
                        return 'Match result (1-2)';
                    }

                    return 'Match result (1x2)'
                },
                'matchResultColspan': function () {
                    return this.has_draw ? 3 : 2;
                }
            },
            methods: {
                reload: function () {
                    var self = this;
                    $.get(this.url, function (data) {
                        self.events = data.events;
                        self.show_extra_odds = data.show_extra_odds;
                        self.loaded = true;
                    });
                },
                placeTip: function (event, pick, type) {
                    var params = $.param({pick: pick, type: type});
                    var dataUrl = this.url + '/events/' + event.id + '/tips/create?' + params;
                    var submitUrl = this.url + '/events/' + event.id + '/tips?' + params;
                    this.opened_event_key = event.id + pick + type;
                    betSlipVue.loadFor(dataUrl, submitUrl);

                    $(window).scrollTop($('#tipWidget').position().top - $('.navbar-main').height());
                },
                oddClass: function (event, pick, type) {
                    return {
                        'outcome-cell': true,
                        active: this.opened_event_key == event.id + pick + type
                    }
                }
            },
            ready: function () {
                this.reload();
            }
        });

        betSlipVue = new Vue({
            el: $tipWidget[0],
            data: {
                url: null,
                url_submit: null,
                loaded: false,
                error: false,
                submit_error: false,
                stake: 1,
                analysis: '',
                odds: 0,
                minimal_odds: 0,
                bookmaker: PINNACLE_ID,
                pinnacle_id: PINNACLE_ID,
                success_message: false,
                original_odds: null,
                result: {}
            },
            watch: {
                bookmaker: function (val) {
                    if (val == PINNACLE_ID) {
                        this.odds = this.original_odds
                    }
                },
                'minimal_odds': function (value) {
                    this.setStakeUnits()
                },
                'odds': function (value) {
                    this.setStakeUnits()
                },
            },
            methods: {
                setStakeUnits: function () {
                    if (!this.minimal_odds) {
                        return this.stake = null;
                    }
                    if (!this.odds) {
                        return this.stake = null;
                    }
                    var secondOdds = this.odds;
                    var estimate = Math.floor((1 / this.minimal_odds * 100) * 100) / 100;
                    var betSizeBankroll = (((secondOdds - 1) * (estimate / 100)) - (1 - estimate / 100)) / (secondOdds - 1) * 100

                    var units = Math.round(betSizeBankroll / 2);

                    if (units < 1) {
                        return this.stake = 1
                    }

                    if (units > 10) {
                        return this.stake = 10
                    }

                    this.stake = units;
                },
                onSubmit: function () {
                    this.submit_error = false;
                    var self = this;
                    $.post(this.url_submit, {
                        stake: this.stake,
                        odds: this.odds,
                        minimal_odds: this.minimal_odds,
                        analysis: this.analysis,
                        bookmaker: this.bookmaker,
                    }, function (result) {
                        window.location.href = result.redirect;
                    }).fail(function (response) {
                        self.submit_error = response.responseJSON.error;
                    });
                },
                close: function () {
                    this.loaded = false;
                    this.url = null;
                    listVue.opened_event_key = null;
                },
                loadFor: function (url, submit_url) {
                    var new_url = url;

                    if (new_url === this.url) {
                        this.loaded = false;
                        this.url = null;
                        return;
                    }

                    this.loaded = false;
                    this.error = false;
                    this.submit_error = false;
                    this.success_message = false;
                    this.stake = 1;
                    this.odds = 0;
                    this.minimal_odds = 0;
                    this.bookmaker = PINNACLE_ID;
                    this.analysis = '';
                    this.result = {};

                    this.url = new_url;
                    this.url_submit = submit_url;

                    var self = this;
                    $.get(this.url, function (result) {
                        self.result = result;
                        self.odds = result.tip.odds;
                        self.original_odds = result.tip.odds;
                        self.minimal_odds = result.tip.odds;
                        self.loaded = true;
                    }).fail(function (response) {
                        self.loaded = true;
                        self.error = response.responseJSON.error;
                    });
                }
            }
        })
    }

})();