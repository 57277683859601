function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}


function resizePopup(){
    var wrapper = $('.modal.in'),
        content = wrapper.find('.popupWrap');

    if (wrapper.length > 0 && content.length > 0) {
        if ($(this).outerHeight() > content.outerHeight()) {
            content.css({
                'position': 'relative',
                'top': (wrapper.outerHeight()-content.outerHeight())/2 + 'px'
            });
        } else {
            content.css({
                'position': 'static',
                'top': '0px'
            })
        }
    }
    
}

function stickyHeader() {
    "use strict";

    var edge = 0;

    if ($(window).scrollTop() > edge){
        $('.header').removeClass('transformed');
    } else {
        $('.header').addClass('transformed');
    }

}

$(document).ready(function(){
    /**********************************/
    /*              UI                */
    /**********************************/

    $('select').not('.manual-chosen').not('.phpdebugbar-datasets-switcher').chosen({
        disable_search_threshold: 10,
        width: '100%'
    });

    $('input, textarea').placeholder();
    
    $('.jsDatepicker').datepicker({
        dateFormat: 'dd-mm-yy'
    });

    $('.jsUploadFile').on('click', function(e){
        e.preventDefault();
        var fileUploadWrap = $(this).closest('.row').find('.fileUploadInput'),
            fileInput = $(this).closest('.row').find('input[type=file]');
        if (fileUploadWrap.hasClass('hasFile')) {
            fileUploadWrap.removeClass('hasFile');
            $('.text', fileUploadWrap).text(fileUploadWrap.data('placeholder'));
            fileInput.replaceWith(fileInput.clone(true));
        } else {
            fileInput.click();
        }
        
    });

    $('input[type=file]').on('change', function(){
        var filename = $(this).val().split("\\"),
        fileUploadWrap = $(this).closest('.row').find('.fileUploadInput');

        fileUploadWrap.addClass('hasFile');
        
        if (!fileUploadWrap.data('placeholder')) {
            fileUploadWrap.data('placeholder', fileUploadWrap.text());
        }

        $('.text', fileUploadWrap).text(filename[filename.length - 1]);
    });

    $('.jsSearchTrigger').on('click', function(e){
        e.preventDefault();
        e.stopPropagation()
        
        $('.header').find('.formSearch').fadeIn(500);
        $(this).fadeOut(500);

        $('.header').addClass('searchOpen');

        $('html').on('click.dropSearch', function(event) { 
            if($(event.target).closest('.formSearch').length === 0) {
                $('.header').removeClass('searchOpen');
                $('.header .formSearch').fadeOut(500);
                $('.header .search').fadeIn(500);
                $(document).off('click.dropSearch');
            }        
        })
    });

    /**********************************/
    /*              Timer             */
    /**********************************/
    
    if ($('.jsTipTimer').length > 0){
        
        setInterval(function(){
            $('.jsTipTimer').each(function(){
            var timeVal = $(this).html().split(' : ');

            --timeVal[2];

            if ((timeVal[2])< 0) {
                --timeVal[1];
                timeVal[2] = 59;
            }
            
            timeVal[2] = zeroPad(timeVal[2], 2);

            if (timeVal[1]<0) {
                --timeVal[0];
                timeVal[1] = 59;
            }
            
            timeVal[1] = zeroPad(timeVal[1], 2);

            if (timeVal[0]<=0){
                timeVal[0] = 0;
            }
            
            timeVal[0] = zeroPad(timeVal[0], 2);
            
            
            $(this).html(timeVal.join(' : '));
            });
        }, 1000);
    }
    /**********************************/
    /*              MODALS            */
    /**********************************/
     $('.modal').on('shown.bs.modal', function() {
        resizePopup();
     });

     $('[data-open-another]').on('click', function(){
        var target = $(this).data('open-another');
        $('.modal').modal('hide');
        $(target).modal('show');
     })

     $(window).on('resize', function(){
        resizePopup();
     });

    /**********************************/
    /*              chart         */
    /**********************************/
     
    if($('#chart').length>0) {
        var ctx = $("#chart").get(0).getContext("2d");
        // This will get the first returned node in the jQuery collection.
        var myNewChart = new Chart(ctx).Line(chartData, {
            bezierCurve: false,
            tooltipFillColor: "#a5c94b",
            tooltipFontFamily: "'Roboto', sans-serif",
            tooltipFontSize: 12,
            tooltipFontColor: '#2c303b',
            tooltipYPadding: 12,
            tooltipXPadding: 12,
            tooltipCornerRadius: 3,
            pointDotRadius: 5,
            pointDotStrokeWidth : 2
        });
    }

    if ($('.header').hasClass('transformed')) {
        $(window).on('scroll', function(){
            stickyHeader();
        });
    }

    $(window).trigger('scroll');
});

