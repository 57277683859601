(function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
            $('.navbar').addClass("sticky");
        }
        else {
            $('.navbar').removeClass("sticky");
        }
    });

    $.ajaxSetup({
        headers: {'X-XSRF-TOKEN': $('meta[name=_token]').attr('content')}
    });

    jQuery.fn.shake = function () {
        this.each(function (i) {
            $(this).css({"position": "relative"});
            for (var x = 1; x <= 3; x++) {
                $(this).animate({left: -25}, 10).animate({left: 0}, 50).animate({left: 25}, 10).animate({left: 0}, 50);
            }
        });
        return this;
    }

    function bookmakerFieldToggleForNewComplaint() {
        $('#bookmaker_name').parent().hide();
        $('select#bookmaker').change(function () {
            if ($(this).val() === 'other') {
                $('#bookmaker_name').parent().slideDown();
            } else {
                $('#bookmaker_name').parent().slideUp();
            }
        });
        $('select#bookmaker').change();
    }

    function loginAndRegisterAndResetAjaxSend() {
        $('#loginForm').submit(function (e) {
            e.preventDefault();
            var btn = $(this).find('button[type=submit]');
            btn.prop('disabled', true);
            $.post('/auth/login', $(this).serialize(), function (data) {
                if (data.status == 'success') {
                    if (data.close_window) {
                        window.close()
                    } else if (data.redirect) {
                        window.location.href = data.redirect;
                    } else {
                        window.location.reload();
                    }
                } else {
                    btn.prop('disabled', false);
                    $("#loginForm").parents('.popup').shake();
                }
            })
        });

        $('#subscribe-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var btn = $(this).find('button[type=submit]');
            btn.prop('disabled', true);
            $.post('/subscriptions', $(this).serialize(), function (data) {
                if (data.status == 'success') {
                    form.hide();
                    $('#subscriber-email-target').html($('#subscriber-email-source').val());
                    $('#subscribe-success').show();
                } else {
                    btn.prop('disabled', false);
                    form.shake();
                }
            }).fail(function (e) {
                btn.prop('disabled', false);
                form.shake();
            });
        });

        $('#feedbackForm').submit(function (e) {
            e.preventDefault();
            var btn = $(this).find('button[type=submit]');
            btn.prop('disabled', true);
            $.post('/feedback', $(this).serialize(), function (data) {
                if (data.status == 'success') {
                    $("#feedbackForm").replaceWith('<div class="white-medium">' + data.message + '</div>');
                    $('#feedbackModal .popupHeader').hide();
                } else {
                    btn.prop('disabled', false);
                    $("#feedbackForm").shake();
                }
            }).fail(function (e) {
                btn.prop('disabled', false);
                $("#feedbackForm").shake();
            });
        });


        $('#resetForm').submit(function (e) {
            e.preventDefault();
            $('#resetErrorMsg').addClass('hide');
            $('#resetSuccessMsg').addClass('hide');
            var btn = $(this).find('button[type=submit]');
            btn.prop('disabled', true);
            $.post('/password/email', $(this).serialize()).always(function (data) {
                if (data.status == 'passwords.sent') {
                    $('#resetSuccessMsg').removeClass('hide');
                    $('#resetModal .popupFooter').addClass('hide');
                    $('#resetForm').addClass('hide');
                    $('#resetErrorMsg').addClass('hide');
                } else {
                    btn.prop('disabled', false);

                    shake($('#resetErrorMsg').removeClass('hide'));
                    $('#resetSuccessMsg').addClass('hide');
                }
            });
        });


        $('#registerForm').submit(function (e) {
            e.preventDefault();
            var btn = $(this).find('button[type=submit]');
            btn.prop('disabled', true);
            var errors = [];
            $('#registerErrorMsg').addClass('hide');
            $('#registerErrorMsg ul').empty();
            $.post('/auth/register', $(this).serialize()).always(function (data) {
                if (data.status == 'ok') {
                    $('#registerInfoMsg').removeClass('hide')
                        .html(data.message);

                    $('#registerForm').hide();
                } else {
                    btn.prop('disabled', false);

                    errors = [];
                    $.each(data.responseJSON, function (i, value) {
                        $.each(value, function (j, valueInner) {
                            errors.push(valueInner);
                        });
                    });

                    shake($('#registerErrorMsg').removeClass('hide'));

                    $.each(errors, function (i, error) {
                        $('#registerErrorMsg ul').append("<li>" + error + "</li>");
                    });
                }
            });
        });

        $("#fb-sign-up-modal").modal("show");

        $("#fbRegisterForm").submit(function (e) {
            e.preventDefault();
            var errors = [];
            var $registerErrorMsg = $('#fbRegisterErrorMsg');
            var $registerErrorMsgUl = $($registerErrorMsg.find("ul"));
            var $fbRegisterInfoMsg = $("#fbRegisterInfoMsg");
            var $fbRegisterForm = $("#fbRegisterForm");
            var $fbRegisterSubmit = $("#fb-register-submit");
            $registerErrorMsg.addClass('hide');
            $registerErrorMsgUl.empty();
            $fbRegisterSubmit
                .attr('disabled', 'disabled')
                .attr("data-original-value", $fbRegisterSubmit.html())
                .html($fbRegisterSubmit.data("disable-with"));
            $.post('/auth/facebook', $(this).serialize()).always(function (data) {
                if (data.status == 'ok') {
                    window.location.reload();
                } else {
                    errors = [];
                    $.each(data.responseJSON, function (i, value) {
                        $.each(value, function (j, valueInner) {
                            errors.push(valueInner);
                        });
                    });

                    $registerErrorMsg.removeClass('hide');

                    $.each(errors, function (i, error) {
                        $registerErrorMsgUl.append("<li>" + error + "</li>");
                    });

                    $fbRegisterForm.shake();
                }
                $fbRegisterSubmit
                    .attr('disabled', false)
                    .html($fbRegisterSubmit.data("original-value"));
            });
        });
        //
        $("#twitter-sign-up-modal").modal("show");
        $("#twitterRegisterForm").submit(function (e) {
            e.preventDefault();
            var errors = [];
            var $registerErrorMsg = $('#twitterRegisterErrorMsg');
            var $registerErrorMsgUl = $($registerErrorMsg.find("ul"));
            var $twitterRegisterInfoMsg = $("#twitterRegisterInfoMsg");
            var $twitterRegisterForm = $(this);
            var $twitterRegisterSubmit = $("#twitter-register-submit");
            $registerErrorMsg.addClass('hide');
            $registerErrorMsgUl.empty();
            $twitterRegisterSubmit
                .attr('disabled', 'disabled')
                .attr("data-original-value", $twitterRegisterSubmit.html())
                .html($twitterRegisterSubmit.data("disable-with"));
            $.post('/auth/twitter', $twitterRegisterForm.serialize()).always(function (data) {
                if (data.status == 'ok') {
                    $twitterRegisterForm.hide();
                    $("#twitter-sign-up-modal").find('.popupTitle').html(data.message);
                } else {
                    errors = [];
                    $.each(data.responseJSON, function (i, value) {
                        $.each(value, function (j, valueInner) {
                            errors.push(valueInner);
                        });
                    });

                    $registerErrorMsg.removeClass('hide');

                    $.each(errors, function (i, error) {
                        $registerErrorMsgUl.append("<li>" + error + "</li>");
                    });

                    $twitterRegisterForm.shake();
                }
                $twitterRegisterSubmit
                    .attr('disabled', false)
                    .html($twitterRegisterSubmit.data("original-value"));
            });
        });
    }

    //function countUpInit() {
    //    $('.counts-up').each(function () {
    //        var count = new countUp($(this)[0], 0, $(this).data('to'), 0, 4, {useEasing: true}).start();
    //    });
    //}

    function toggleUserFollowing() {
        $('.toggle-user-follow').click(function () {
            var clicked = $(this);
            clicked.fadeTo('fast', 0.3);
            $.post('/user/follow', {slug: $(this).data('slug')}).always(function (data) {
                if (data.status == 'ok') {
                    if (data.follow == true) {
                        clicked.find('.glyphicon').removeClass('glyphicon-plus').addClass('glyphicon-minus');
                        clicked.find('.follow-text').text('UNFOLLOW');
                    } else {
                        clicked.find('.glyphicon').removeClass('glyphicon-minus').addClass('glyphicon-plus');
                        clicked.find('.follow-text').text('FOLLOW');
                    }
                    clicked.fadeTo('fast', 1.0);
                    return;
                }
                window.location.reload();
            });
        });
    }

    function loadLazyImageFullWidth() {
        var $lazyFullWidthImage = $('.lazy-full-image-width');
        $lazyFullWidthImage.each(function (index) {
            $(this).attr('src', $(this).data('src') + "&width=" + $(this).parent().width());
        });
    }

    function initBootstrapTooltips() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    function restrictedCountriesMap() {
        if ($('#restricted-countries-map').length) {
            var colors = {};
            var restrictedColor = "red";
            $('.restricted-countries-list i.restricted').each(function () {
                colors[$(this).data('code')] = restrictedColor;
            });
            $('#restricted-countries-map').vectorMap({
                map: 'world_en',
                backgroundColor: null,
                color: '#ffffff',
                hoverOpacity: 0.7,
                selectedColor: '#666666',
                enableZoom: true,
                showTooltip: true,
                colors: colors,
                normalizeFunction: 'polynomial'
            });
        }
    }

    //function listenAdsVisibility() {
    //    var $window = $(window);
    //
    //    $(document).ready(function () {
    //        $window.scroll(function () {
    //            adsVisibility();
    //        })
    //    });
    //
    //    function adsVisibility() {
    //        var docViewTop = $window.scrollTop();
    //        var docViewBottom = docViewTop + $window.height();
    //
    //        $(".side-ads .ad:visible").each(function () {
    //            var $elem = $(this);
    //            var elemTop = $elem.offset().top;
    //            var elemBottom = elemTop + $elem.height();
    //            if ((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) {
    //                if (parseInt($elem.attr("data-viewed")) < 1) {
    //                    $.get($elem.data("view-href"));
    //                }
    //                $elem.attr("data-viewed", parseInt($elem.attr("data-viewed")) + 1);
    //            }
    //        });
    //    }
    //}

    function shake($element) {
        var $modal = $element;
        $modal.addClass('shake');
        setTimeout(function () {
            $modal.removeClass('shake');
        }, 1000);
    }

    //function initPromoSlider() {
    //    var targetClass = '.swiper-container--promo',
    //        target = $(targetClass);
    //
    //    if (target.length && $('.swiper-slide', target).length > 1) {
    //        var swiper = new Swiper(targetClass, {
    //            pagination: '.swiper-pagination',
    //            paginationClickable: '.swiper-pagination',
    //            autoplay: 4500,
    //            autoplayDisableOnInteraction: true
    //        });
    //    }
    //}

    function newComplaintForm() {
        if ($('.new-complaint-form').length > 0) {
            var $reasonDescription = $('.new-complaint-form').find('.reason-description');
            var descriptions = $reasonDescription.data('description');
            var $later = $('.new-complaint-form').find('.later');

            var $otherReason = $('.new-complaint-form').find('#other_reason').parent();

            if ($('.new-complaint-form #reason').val() != '') {
                $later.slideDown();
                $reasonDescription.html(descriptions[$('.new-complaint-form #reason').val()] || '');
            }

            if ($('.new-complaint-form #reason').val() == 'other') {
                $otherReason.show();
            } else {
                $otherReason.hide();
            }

            $('.new-complaint-form #reason').change(function () {
                if (!$later.is(':visible')) {
                    $later.slideDown();
                }

                if ($(this).val() == 'other') {
                    $otherReason.show();
                } else {
                    $otherReason.hide();
                }

                $reasonDescription.html(descriptions[$(this).val()] || '');
            });
        }
    }

    function initProTipstersGraph() {
        var $graph = $('.pro-tipster-graph');

        $graph.each(function (i) {
            var div = $(this),
                url = $(this).data('url'),
                statsDiv = $('.j-pro-tipster-stats').eq(i);

            $.getJSON(url, {}, function (data) {
                var series = data.series,
                    stats = data.stats;

                $('.j-total', statsDiv).html(stats.total);
                $('.j-yield', statsDiv).html(stats.yield);
                $('.j-profit', statsDiv).html(stats.profit);
                $('.j-avg_odds', statsDiv).html(stats.avg_odds);

                div.highcharts({
                    chart: {
                        backgroundColor: null
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        labels: {
                            enabled: false
                        },
                        title: {
                            text: null
                        },
                        tickInterval: 1,
                        gridLineWidth: 1,
                        minorTickLength: 0,
                        tickLength: 0,
                        // endOnTick: true,
                        min: 0
                    },
                    yAxis: {
                        labels: {
                            enabled: false
                        },
                        title: {
                            text: null
                        },

                        tickInterval: 1,
                        gridLineWidth: 1,
                        minorTickLength: 0,
                        tickLength: 0,
                        endOnTick: true,
                        plotLines: [{
                            value: 0,
                            color: '#000',
                            dashStyle: 'solid',
                            width: 1,
                            zIndex: 3
                        }]
                    },
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        name: 'Profit',
                        color: "#65af9b",
                        data: series,
                        type: "area",
                        fillColor: {
                            linearGradient: [0, 0, 0, 300],
                            stops: [
                                [0, 'rgba(178, 215, 205, 6)'],
                                [1, 'rgba(178, 215, 205, 0)']
                            ]
                        },
                        negativeColor: 'rgb(255, 77, 77)',
                        negativeFillColor: {
                            linearGradient: [0, 0, 0, 300],
                            stops: [
                                [0, 'rgba(255, 77, 77, 0)'],
                                [1, 'rgba(255, 77, 77, 6)'],
                            ]
                        }
                    }],
                    plotOptions: {
                        series: {
                            turboThreshold: 0,
                            marker: {
                                enabled: false
                            },
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        }
                    },
                    credits: false
                });
            });
        });
    }

    function categorySwitcher() {
        var $selectCategory = $('.switch-category');

        if ($selectCategory.length !== 0) {
            $selectCategory.change(function () {
                var category = $(this).val();
                var url = $(this).data('url');
                if (category) {
                    window.location.href = "/" + url + "?category=" + category;
                } else {
                    window.location.href = "/" + url;
                }
            });
        }

    }

    function readMore() {
        $('.j-read-more').expander({
            slicePoint: 350,
            expandText: 'Read more',
            userCollapseText: 'Read less',
            expandEffect: 'fadeIn',
            collapseEffect: 'fadeOut'
        });

        $('.j-read-more-service').expander({
            slicePoint: 350,
            expandText: 'Read more',
            userCollapseText: 'Read less',
            expandEffect: 'fadeIn',
            collapseEffect: 'fadeOut',
            expandPrefix: ''
        });

        $('.service-text .more-link').on('click', function (e) {
            e.stopPropagation();
            return false;
        });

        $('.j-read-more-service').on('click', '.summary', function (e) {
            e.stopPropagation();
            $(this).find('.more-link').trigger('click');
        });
    }

    function scrollToTop() {
        var target = $('#scrollToTop');
        if (target.length) {
            $(window).on('scroll', function () {
                if ($(window).scrollTop() > 0) {
                    target.show();
                } else {
                    target.hide();
                }
            });

            target.on('click', function () {
                $('html, body').animate({scrollTop: 0});
            })
        }
    }

    function deals() {
        $('.j-deals-equal-height').equalHeights();
    }

    function kellyCalculator() {
        var kellyCalculatorErrorNote = $('.tools-container').data('error-text');

        $('.kelly-calculator input').on('keydown, keyup', function (e) {
            $(this).commaToDot(e);
            kellyCalculate();
        });

        $('.j-kelly-clear').on('click', function () {
            $('.kelly-calculator input').val('');
            $('.result-wrap').html('');
            return false;
        });

        $('.j-kelly-estimate-percentage').on('keydown, keyup', function (e) {
            if ($(this).val()) {
                var percentage = Math.floor((1 / $(this).val() * 100) * 100) / 100;
                $('.j-kelly-estimate-odds').val(percentage);
            }
        });

        $('.j-kelly-estimate-odds').on('keydown, keyup', function (e) {
            if ($(this).val()) {
                var percentage = Math.floor((1 / $(this).val() * 100) * 100) / 100;
                $('.j-kelly-estimate-percentage').val(percentage);
                kellyCalculate();
            }
        });

        function kellyCalculate() {
            var
                odds = $('.j-kelly-odds').val(),
                estimate = $('.j-kelly-estimate-percentage').val(),
                bankroll = $('.j-kelly-bankroll').val(),
                betSizeBankroll = (((odds - 1) * (estimate / 100)) - (1 - estimate / 100)) / (odds - 1) * 100,
                betSize = bankroll * betSizeBankroll / 100;

            betSize = Math.floor(betSize * 100) / 100;
            betSizeBankroll = Math.floor(betSizeBankroll * 100) / 100;

            if (betSize > 0) {
                $('#bet-size').html(betSize);
            } else {
                $('#bet-size').html(kellyCalculatorErrorNote);
            }

            if (betSizeBankroll > 0) {
                $('#bet-size-bankroll').html(betSizeBankroll);
            } else {
                $('#bet-size-bankroll').html(kellyCalculatorErrorNote);
            }
        }
    }

    function systemsCalculator() {
        var parent = $('.j-systems-calculator'),
            $matchesCount = $('.j-matches-count', parent),
            $matches = $('.j-matches', parent),
            $stakes = $('.j-stakes', parent),
            matchTemplate = $('#j-match-row').html(),
            stakeTemplate = $('#j-stake-row').html(),
            $statsMatchesCount = $('.j-stats-matches-count', parent),
            maxMatches = 12;

        $('.j-change-match-count', parent).on('click', function () {
            var action = $(this).data('action'),
                value = $matchesCount.val();

            if (action == 'increase') {
                if (value == maxMatches) {
                    return;
                }
                value++;
            } else if (action == 'decrease') {
                if (value == 0) {
                    return;
                }
                value--;
            }
            populateStakes(value);
            return false;
        });

        $('.j-bet-all', parent).on('keydown, keyup', function () {
            if ($(this).val()) {
                $('.j-stake-size', parent).val($(this).val());
            }
        });

        $('.j-reset', parent).on('click', function () {
            $matchesCount.val(0);
            $statsMatchesCount.html(0);
            populateStakes();
        });

        parent.on('keydown, keyup', '.calculator-input', function () {
            calculate();
        });

        parent.on('change', '.j-match-result', function () {
            calculate();
        });

        $matchesCount.on('keydown, keyup', function () {
            var value = parseInt($(this).val());

            if (value < 0 || value > 12) {
                return;
            } else {
                populateStakes(value);
            }
        });

        function getNumberOfBets(total, current) {
            return factorial(total) / (factorial(current) * factorial(total - current));
        }

        function populateStakes(matchesCount) {
            var words = {
                1: 'singles',
                2: 'doubles',
                3: 'trebles',
                4: '4folds',
                5: '5folds',
                6: '6folds',
                7: '7folds',
                8: '8folds',
                9: '9folds',
                10: '10folds',
                11: '11folds',
                12: '12folds',
            };

            $stakes.html('');
            $matches.html('');
            for (var i = 1; i <= matchesCount; i++) {
                var word = words[i],
                    numberOfBets = getNumberOfBets(matchesCount, i),
                    template = stakeTemplate.replace(/%word%/g, word)
                        .replace(/%number_of_bets%/g, numberOfBets);
                $stakes.append(template);

                var template = matchTemplate.replace(/%match_number%/g, i);
                $matches.append(template);
            }

            $('.j-match-result', parent).chosen({
                disable_search: true,
                allow_single_deselect: true
            });

            if ($('.j-bet-all').val()) {
                $('.j-stake-size', parent).val($('.j-bet-all').val());
            }

            $matchesCount.val(matchesCount);
            $statsMatchesCount.html(matchesCount);
            calculate();
        }

        function calculate() {
            var totalStake = 0,
                potential = 0,
                betsCount = 0,
                oddsArray = new Array;

            $('.j-odds', parent).each(function () {
                var matchResult = $(this).parent('.match-data').find('.j-match-result').val(),
                    odds = $(this).val();

                if (matchResult == 'void') {
                    odds = 1;
                } else if (matchResult == 'loss') {
                    odds = 0;
                }
                oddsArray.push(odds);
            });

            $('.j-stake-size').each(function (counter) {
                var self = $(this),
                    combinations = combine(oddsArray, counter + 1, counter + 1),
                    tempOddsSum = 0;

                if ($(this).val()) {
                    betsCount += $(this).data('number-of-bets');
                }

                totalStake += self.data('number-of-bets') * self.val();

                for (var i = 0; i < combinations.length; i++) {
                    var tempOdd = 1;
                    for (var j = 0; j < combinations[i].length; j++) {
                        tempOdd = tempOdd * combinations[i][j];
                        tempOdd = parseFloat(tempOdd.toFixed(4));
                    }
                    tempOddsSum += tempOdd;
                }
                potential += tempOddsSum * self.val();
            });

            $('.j-stats-bets-count', parent).html(betsCount);
            $('.j-potential', parent).html(potential.toFixed(2));
            $('.j-total-stake', parent).html(totalStake.toFixed(2));
        }

        function combine(a, min, max) {
            var fn = function (n, src, got, all) {
                if (n == 0) {
                    if (got.length > 0) {
                        all[all.length] = got;
                    }
                    return;
                }
                for (var j = 0; j < src.length; j++) {
                    fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
                }
                return;
            }
            var all = [];
            for (var i = min; i <= max; i++) {
                fn(i, a, [], all);
            }
            //all.push(a); // since we do not need combination of all items
            return all;
        }

        function factorial(num) {
            var rval = 1;
            for (var i = 2; i <= num; i++)
                rval = rval * i;
            return rval;
        }

    }

    function oddsConverter() {
        var $decimal = $('[data-odds-type="decimal"]'),
            $fractional = $('[data-odds-type="fractional"]'),
            $american = $('[data-odds-type="american"]'),
            $implied = $('[data-odds-type="implied"]');

        function calculateDecimalOdds(americanOdds) {
            var decimalOdds;

            if (americanOdds > 0) {
                decimalOdds = (americanOdds / 100) + 1;
            } else {
                decimalOdds = parseFloat((100 / Math.abs(americanOdds))) + 1;
            }

            return Math.floor(decimalOdds * 100) / 100;
        }

        function calculateAmericanOdds(decimalOdds) {
            var americanOdds;

            if (decimalOdds >= 2) {
                americanOdds = (decimalOdds - 1) * 100;
            } else {
                americanOdds = (-100) / (decimalOdds - 1);
            }

            americanOdds = Math.floor(americanOdds * 100) / 100;

            if (americanOdds > 0) {
                americanOdds = '+' + americanOdds;
            }
            return americanOdds;
        }

        $american.on('catchInput', function (e) {
            var value = parseInt($(this).val());

            if (!value) {
                return;
            }

            $decimal.val(calculateDecimalOdds(value));
            $implied.trigger('calculate');
            $fractional.trigger('calculate');
        });

        $decimal.on('catchInput', function (e) {
            var value = parseFloat($(this).val());

            if (!value) {
                return;
            }
            $american.val(calculateAmericanOdds(value));
            $implied.trigger('calculate');
            $fractional.trigger('calculate');
        });

        $fractional.on('catchInput', function (e) {
            var value = $(this).val();
            if (!value || value.indexOf('/') == -1) {
                return;
            }
            var bits = value.split('/');

            if (bits[1]) {
                var decimal = bits[0] / bits[1] + 1;
                $decimal.val(decimal);
                $implied.trigger('calculate');
                $fractional.trigger('calculate');
                $american.val(calculateAmericanOdds($decimal.val()));
            }
        });

        $implied.on('calculate', function (e) {
            $(this).val(Math.round(1 / $decimal.val() * 100 * 100) / 100 + '%');
        });

        $fractional.on('calculate', function (e) {
            var frac = new Fraction()
            frac.convert($decimal.val() - 1, true);
            $(this).val(frac.toString());
        });

        var selector = $('#odds-converter .calculator-input');
        selector.on('keydown, keyup', function (e) {
            if (!$(this).val()) {
                selector.val('');
                return;
            }

            $(this).commaToDot(e);

            $(this).trigger('catchInput');
        });
    }

    function tipsPage() {
        var filter = $('#tipsFilter'),
            button = $('.j-show-tips-element');

        button.click(function () {
            if ($(this).hasClass('active')) {
                var a = $(this).data('target');
                var target = $(a);

                if ($(this).hasClass('visible')) {
                    target.addClass('active');
                    button.removeClass('visible');
                }
            }
        });

        $('.j-close-filter').click(function () {
            $('.tips-filter-form-wrap').removeClass('active');
            button.addClass('visible');
            return false;
        });

        /**/
        (function () {

            var $tipsForm = $('.tips-form');

            if ($tipsForm.length > 0) {
                var $type = $tipsForm.find('#type');
                var $pick = $tipsForm.find('#pick').parent();
                var $extra = $tipsForm.find('#extra').parent();
                var $totalType = $tipsForm.find('#total_type').parent();

                $extra.hide();
                $totalType.hide();

                var rules = {
                    outright: {
                        picks: [1]
                    },
                    h2h: {
                        picks: [1, 2]
                    },
                    handicap: {
                        picks: [1, 2],
                        extra_visible: true,
                    },
                    totals: {
                        extra_visible: true,
                        total_type_visible: true,
                        pick_hidden: true
                    },
                    other: {
                        extra_visible: true,
                        pick_hidden: true
                    },
                };

                $type.change(function () {
                    var self = $(this);
                    $extra.hide();
                    $totalType.hide();
                    $pick.show();

                    var value = self.val();
                    var rules_for = rules[value] || {};
                    var availablePicks = rules_for['picks'];
                    var extraVisible = rules_for['extra_visible'];
                    var totalTypeVisible = rules_for['total_type_visible'];
                    var pickHidden = rules_for['pick_hidden'];

                    if (extraVisible === true) {
                        $extra.show();
                    }

                    if (pickHidden === true) {
                        $pick.hide();
                    }

                    if (totalTypeVisible === true) {
                        $totalType.show();
                    }

                    if (availablePicks === undefined) { // all
                        $pick.find("option").prop('disabled', false).trigger('chosen:updated');
                    } else {
                        $pick.find("option").prop('disabled', true).trigger('chosen:updated');
                        $.each(availablePicks, function (_, pick) {
                            $pick.find('option[value="' + pick + '"]').prop('disabled', false).trigger('chosen:updated');
                        });
                    }
                });

                $type.change();
            }
        })();
        /**/
    }

    function bookmakerRating() {
        $('.j-knob').knob();

    }

    function tipRating() {
        var $starInput = $('.star-input');
        $starInput.on('click.starClick', function () {
            $starInput.removeClass('active');

            for (var i = $('input', this).val(); i != 0; i--) {
                $starInput.eq(i - 1).addClass('active');
            }
        });

        var currentRating = $('.single-tip-rating-stars').data('rated');
        if (currentRating) {
            $('#tip-rating-' + currentRating).trigger('click');

            if ($('.single-tip-rating-stars').hasClass('is-rated')) {
                $starInput.unbind('click.starClick');
            }
        }

        $('.submit-tip-rating').click(function (e) {
            e.preventDefault();
            var $self = $(this);
            var rating = $starInput.filter('.active').length;
            if (rating == 0) return;
            $.post($self.data('url'), {_method: 'PUT', rating: rating}, function (data) {
                window.location.reload();
            });
        });
    }

    jQuery.fn.extend({
        commaToDot: function (e) {
            var key = e.which ? e.which : event.keyCode;
            if (key == 110 || key == 188) {
                e.preventDefault();
                var value = this.val();
                this.val(value.replace(",", "."));
            }
        }
    });


    $('#loginModal').on('shown.bs.modal', function () {
        $(this).find('input[type=email]').focus();
    });

    $('#registerModal').on('shown.bs.modal', function () {
        $(this).find('input').not('[type="hidden"]').eq(0).focus();
    });

    $(document.body).on('click', '.load-ajax', function (e) {
        e.preventDefault();
        var $link = $(this);
        var id = $link.data('reload-id');
        var url = $link.attr('href');
        var $reloadable = $("#" + id);
        $reloadable.fadeTo('fast', 0.5);

        $.get(url, function (data) {
            $reloadable.replaceWith(data);
            if (id === 'dashboard-tipsters-list') {
                initProTipstersGraph();
            }
        });
    })

    function initBlogEventGraph() {
        var $g = $('#blog-event-odds-graph');

        if ($g.length == 0) {
            return;
        }

        $.getJSON($g.data('url'), function (data) {
            var series = data.series;
            var counter = data.counter;

            if (counter === 0) {
                return;
            }

            drawEventGraph($g, series);
        });
    }

    function initRegisterAutoPopup() {
        if (window.location.search.indexOf('register=1') != -1) {
            $('a[data-target="#registerModal"]').eq(0).trigger('click');
        }
    }

    newComplaintForm();
    restrictedCountriesMap();
    //initBootstrapTooltips();
    loadLazyImageFullWidth();
    toggleUserFollowing();
    bookmakerFieldToggleForNewComplaint();
    loginAndRegisterAndResetAjaxSend();
    //countUpInit();
    //listenAdsVisibility();
    //initPromoSlider();
    categorySwitcher();
    readMore();
    scrollToTop();
    deals();
    oddsConverter();
    kellyCalculator();
    systemsCalculator();
    tipsPage();
    bookmakerRating();
    tipRating();
    initBlogEventGraph();
    initRegisterAutoPopup();
    initProTipstersGraph();
    initUserCompetitions();

})();

function initUserCompetitions() {
    var rows = $('.j-user-competitions tbody tr'),
        trigger = $('.j-user-competitions-trigger');

    if (rows.length <= 3) {
        trigger.hide();
    } else {
        trigger.on('click', function () {
            rows.show();
            $(this).hide();
            return false;
        });

        rows.hide();

        rows.slice(0, 3).show();
    }
}

function drawEventGraph($target, series) {
    $target.highcharts('StockChart', {
        title: {
            text: null
        },
        rangeSelector: {
            selected: 0,
            buttons: [
                {
                    type: 'all',
                    text: 'All'
                },
                {
                    type: 'month',
                    count: 1,
                    text: '1m'
                },
                {
                    type: 'week',
                    count: 1,
                    text: '1w'
                },
                {
                    type: 'hour',
                    count: 24,
                    text: '24h'
                },
                {
                    type: 'hour',
                    count: 12,
                    text: '12h'
                },
                {
                    type: 'hour',
                    count: 6,
                    text: '6h'
                },
                {
                    type: 'hour',
                    count: 3,
                    text: '3h'
                },
                {
                    type: 'hour',
                    count: 1,
                    text: '1h'
                }],
        },
        plotOptions: {
            line: {
                marker: {
                    states: {hover: {enabled: false}}
                }
            }
        },
        xAxis: {
            allowDecimals: false,
            minorTickLength: 0,
            tickLength: 0,
            maxZoom: 5,
            showLastLabel: true,
            endOnTick: true,
            min: 0
        },
        yAxis: [{
            title: {
                text: null
            },
            plotLines: [{
                value: 0,
                width: 1
            }]
        }, {
            linkedTo: 0,
            opposite: false
        }],
        legend: {
            enabled: true
        },
        tooltip: {
            xDateFormat: '%Y-%m-%d %H:%M',
            valueDecimals: 3
        },
        series: series,
        credits: false,
        chart: {
            backgroundColor: '#f0f0f2',
            type: 'line'
        },
        colors: ['#293243', '#30a73b', '#ff5400']
    });
}