$(function () {

    var $graph = $('#user-profit-graph');

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    if ($graph.length > 0) {

        Vue.directive('chosen', {
            twoWay: true, // note the two-way binding
            bind: function () {
                $(this.el)
                    .chosen({
                        disable_search_threshold: 10
                    })
                    .change(function (ev) {
                        this.set(this.el.value);
                    }.bind(this));
            }
        });

        var url = $('.graph').data('url');

        new Vue({
            el: "#user-profile-stats",
            data: {
                chartData: {},
                url: url,
                sport: getParameterByName('sport'),
                tipsPage: getParameterByName('page'),
                league: "",
                tip_origin: "",
                graph_type: "",
                tip_origin_options: [],
                sport_options: [],
                graph_types: [
                    {text: 'Actual stakes', value: ''},
                    {text: 'Even stakes', value: 'even_stakes'}
                ],
                showing_minimal_stats: false,
                showing_closing_stats: false,
                is_pro: false,
                total: undefined,
                won: undefined,
                lost: undefined,
                avg_odds: undefined,
                avg_closing_odds: undefined,
                avg_minimal_odds: undefined,
                profit: undefined,
                closing_odds_profit: undefined,
                yield: undefined,
                closing_odds_yield: undefined,
                minimal_odds_yield: undefined,
                minimal_odds_profit: undefined,
                series: undefined,
                labels: undefined,
                turnover: undefined,
                yield_even: undefined,
                even_stakes_profit: undefined,
            },

            computed: {

            },

            watch: {
                'showing_minimal_stats': function () {
                  this.reload();
                },
                'showing_closing_stats': function () {
                    this.reload();
                },
                'sport': function () {
                    this.tipsPage = '';
                    this.reload();
                },
                'league': function () {
                    this.reload();
                },
                'graph_type': function () {
                    this.reload();
                },
                'tip_origin': function (val) {
                    this.reload();
                }
            },


            methods: {

                reload: function () {
                    var self = this;
                    var fields = {
                        graph: this.graph,
                        sport: this.sport,
                        league: this.league,
                        tip_origin: this.tip_origin,
                        graph_type: this.graph_type,
                        showing_closing_stats: this.showing_closing_stats,
                        showing_minimal_stats: this.showing_minimal_stats,
                        page: this.tipsPage
                    };

                    $.getJSON(url, fields, function (data) {
                        var series = data.series;
                        var seriesClosing = data.series_closing;
                        var seriesMinimal = data.series_minimal;
                        var labels = data.labels;

                        var tipsView = data.tipsView;

                        self.setNewStats(data.stats);
                        self.tip_origin_options = data.tip_origin_options;
                        self.sport_options = data.sport_options;

                        self.chartData = {
                            labels: labels,
                            series: series,
                            seriesClosing: seriesClosing,
                            seriesMinimal: seriesMinimal,
                        };

                        self.createChart();

                        if (tipsView !== null) {
                            $('#tips-holder').html(tipsView);
                        }
                    });
                },

                createChart: function () {
                    var labels = this.chartData.labels;
                    var series = this.chartData.series;
                    var seriesClosing = this.chartData.seriesClosing;
                    var seriesMinimal = this.chartData.seriesMinimal;

                    var allSeries = [{
                        name: 'profit',
                        color: "#9d9b9b",
                        data: series
                    }];

                    if (seriesClosing && this.showing_closing_stats) {
                        allSeries.push({
                            name: 'closing',
                            color: "#5a7ca7",
                            data: seriesClosing
                        })
                    }

                    if (seriesMinimal && this.showing_minimal_stats) {
                        allSeries.push({
                            name: 'minimal',
                            color: "#ff6244",
                            data: seriesMinimal
                        })
                    }

                    $graph.highcharts({
                        chart: {
                            backgroundColor: null
                        },
                        title: {
                            text: null
                        },
                        xAxis: {
                            allowDecimals: false,
                            gridLineWidth: 0.3,
                            gridLineColor: 'rgba(255,255,255,0.4)',
                            lineColor: '',
                            minorTickLength: 0,
                            tickLength: 0,
                            maxZoom: 5,
                            showLastLabel: true,
                            endOnTick: true,
                            min: 0
                        },
                        yAxis: {
                            gridLineWidth: 0.3,
                            gridLineColor: 'rgba(255,255,255,0.4)',
                            lineColor: '',
                            //gridLineDashStyle: "Dot",
                            title: {
                                text: null
                            },
                            plotLines: [{
                                value: 0,
                                width: 1
                            }]
                        },
                        tooltip: {
                            crosshairs: true,
                            useHTML: true,
                            formatter: function () {
                                if (this.point.date === undefined) {
                                    return "";
                                }
                                var profit = (this.point.y <= 0 ? '' : '+') + this.point.y;
                                var betWord = this.point.x == 1 ? 'bet' : 'bets';

                                var introName;

                                if (this.series.name === 'profit') {
                                    introName = "Profit";
                                } else if(this.series.name === 'closing') {
                                    introName = $graph.attr('data-title-closing-odds')
                                } else if (this.series.name === 'minimal') {
                                    introName = $graph.attr('data-title-minimal-odds')
                                }
                                
                                return introName + ' ' + profit + ' after ' + this.point.x + ' ' + betWord + '<br/>on ' + this.point.date;
                            }
                        },
                        legend: {
                            enabled: false
                        },
                        series: allSeries,
                        plotOptions: {
                            series: {
                                turboThreshold: 0
                            }
                        },
                        credits: false
                    });
                },

                setNewStats: function (stats) {
                    var self = this;
                    for (var k in stats) {
                        if (stats.hasOwnProperty(k)) {
                            self[k] = stats[k];
                        }
                    }

                    setTimeout(function () {
                        $('select.user-stats-league-filter').trigger("chosen:updated");
                        $('select.user-stats-sport-filter').trigger("chosen:updated");
                        $('select.user-stats-graph-type-filter').trigger("chosen:updated");
                    }, 300)
                },

                init: function () {
                    var defaultTipOrigin = $('.graph').data('competition');

                    if (!defaultTipOrigin) {
                        defaultTipOrigin = 'non_competition';
                    }

                    this.tip_origin = defaultTipOrigin;
                }
            },
            created: function () {
                var self = this;
                $('.user-tips-download-btn').click(function (e) {
                    $(this).attr('href', $(this).data('href') + "?tip_origin=" + self.tip_origin)
                });
                this.init();
            }
        });
    }
});
