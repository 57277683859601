(function () {
    var PINNACLE_ID = '2'

    var REFRESH_RATE = 5 * 1000;

    var $sportsItems = $('.competition-section .sports-list li.load-inline');

    $sportsItems.find('.url-source').click(function (e) {
        e.preventDefault();

        var $link = $(this);
        var $li = $link.parents('.load-inline');

        $sportsItems.not($li).removeClass('active');

        $li.toggleClass('active');

        if (!$li.hasClass('active')) {
            return;
        }

        var url = $link.attr('href');

        $li.find('.url-target').load(url);
    });

    var $competitionTipWidget = $('#competitionTipWidget');
    var $competitionEventsList = $('#competitionEventsList');

    var betSlipVue;

    var AVAILABLE_ODDS = [
        'odds_home',
        'odds_draw',
        'odds_away',
        'totals_over',
        'totals_under',
        'handicap_home_odds',
        'handicap_away_odds'
    ];

    if ($competitionTipWidget.length) {

        var listVue = new Vue({
            el: $competitionEventsList[0],
            data: {
                loaded: false,
                events: [],
                previous_events: [],
                history: {},
                url: $competitionEventsList.data('url'),
                show_extra_odds: true,
                opened_event_key: null
            },
            computed: {
                'extra_odds': function () {
                    return this.show_extra_odds;
                },
                'has_draw': function () {
                    var event = this.events[0];

                    return !(event && !event.odds_draw);
                },
                'matchResultTitle': function () {
                    if (!this.has_draw) {
                        return 'Match result (1-2)';
                    }

                    return 'Match result (1x2)'
                },
                'matchResultColspan': function () {
                    return this.has_draw ? 3 : 2;
                }
            },
            methods: {
                reload: function () {
                    var self = this;
                    $.get(this.url, function (data) {
                        self.previous_events = self.events;
                        self.events = data.events;
                        self.processHistory();
                        self.show_extra_odds = data.show_extra_odds;
                        self.loaded = true;
                    });
                },
                placeTip: function (event, pick, type) {
                    var params = $.param({pick: pick, type: type});
                    var dataUrl = this.url + '/events/' + event.id + '/tips/create?' + params;
                    var submitUrl = this.url + '/events/' + event.id + '/tips?' + params;
                    this.opened_event_key = event.id + pick + type;
                    betSlipVue.loadFor(dataUrl, submitUrl);

                    $(window).scrollTop($('#competitionTipWidget').position().top - $('.navbar-main').height());
                },
                oddClass: function (event, pick, type) {
                    return {
                        'outcome-cell': true,
                        active: this.opened_event_key == event.id + pick + type
                    }
                },
                processHistory: function () {
                    if (!this.previous_events.length) {
                        return;
                    }

                    for (var i = 0, iLen = this.events.length; i < iLen; i++) {

                        var event = this.events[i];
                        var previous = this.previous(event.id);


                        if (!previous) {
                            return;
                        }

                        if (event.updated_diff > 60) {
                            this.history[event.id] = {};
                            return;
                        }


                        for (var j = 0, jLen = AVAILABLE_ODDS.length; j < jLen; j++) {

                            var odd_key = AVAILABLE_ODDS[j];
                            var c = parseFloat(event[odd_key]);
                            var p = parseFloat(previous[odd_key]);
                            if (c && p && c != p) {
                                var historyFor = this.history[event.id];
                                if (!historyFor) {
                                    this.history[event.id] = {};
                                }
                                this.history[event.id][odd_key] = p;
                            }
                        }

                    }
                },
                oddsChange: function (event, odds_key) {

                    if (!event[odds_key]) {
                        return {};
                    }

                    var previous = this.previous(event.id);

                    if (!previous || !previous[odds_key]) {
                        return {};
                    }

                    var c = parseFloat(event[odds_key]);
                    var p = parseFloat(this.history[event.id][odds_key]);

                    var changed = c && p && c != p;

                    if (!changed) {
                        return {};
                    }

                    var down = c < p;

                    return {
                        'odds-status': true,
                        'odds-status-down': down,
                        'odds-status-up': !down
                    };
                },
                previous: function (event_id) {
                    for (var i = 0, iLen = this.previous_events.length; i < iLen; i++) {

                        if (this.previous_events[i].id == event_id) return this.previous_events[i];
                    }
                }
            },
            ready: function () {
                this.reload();
                var self = this;
                setInterval(function () {
                    self.reload();
                }, REFRESH_RATE);
            }
        });

        betSlipVue = new Vue({
            el: $competitionTipWidget[0],
            data: {
                url: null,
                url_submit: null,
                loaded: false,
                error: false,
                submit_error: false,
                stake: 0,
                posting_as_tip: false,
                posting_in_competition: true,
                minimal_odds: 0,
                second_odds: null,
                stake_units: null,
                bookmaker: PINNACLE_ID,
                pinnacle_id: PINNACLE_ID,
                success_message: false,
                result: {},
                analysis: ''
            },
            watch: {
                'minimal_odds': function (value) {
                    this.setStakeUnits()
                },
                'second_odds': function (value) {
                    this.setStakeUnits()
                },
                'bookmaker': function (id) {
                    if (id != PINNACLE_ID && this.result.tip.odds == this.second_odds) {
                        this.second_odds = null;
                    }
                    if (id == PINNACLE_ID) {
                        this.second_odds = this.result.tip.odds
                    }
                }
            },
            methods: {
                setStakeUnits: function () {
                    if (!this.minimal_odds) {
                        return this.stake_units = null;
                    }
                    if (!this.second_odds) {
                        return this.stake_units = null;
                    }
                    var secondOdds = this.second_odds;
                    var estimate = Math.floor((1 / this.minimal_odds * 100) * 100) / 100;
                    var betSizeBankroll = (((secondOdds - 1) * (estimate / 100)) - (1 - estimate / 100)) / (secondOdds - 1) * 100

                    var units = Math.round(betSizeBankroll / 2);

                    if (units < 1) {
                        return this.stake_units = null
                    }

                    if (units > 10) {
                        return this.stake_units = 10
                    }

                    this.stake_units = units;
                },
                onSubmit: function () {
                    this.submit_error = false;
                    var self = this;
                    $.post(this.url_submit, {
                        stake: this.stake,
                        odds: this.result.tip.odds,
                        posting_as_tip: this.posting_as_tip,
                        posting_in_competition: this.posting_in_competition,
                        minimal_odds: this.minimal_odds,
                        second_odds: this.second_odds,
                        stake_units: this.stake_units,
                        bookmaker: this.bookmaker,
                        analysis: this.analysis
                    }, function (result) {
                        self.success_message = result.message;
                        listVue.opened_event_key = null;
                    }).fail(function (response) {
                        self.submit_error = response.responseJSON.error;
                    });
                },
                close: function () {
                    this.loaded = false;
                    this.url = null;
                    listVue.opened_event_key = null;
                },
                loadFor: function (url, submit_url) {
                    var new_url = url;

                    if (new_url === this.url) {
                        this.loaded = false;
                        this.url = null;
                        return;
                    }

                    this.loaded = false;
                    this.error = false;
                    this.submit_error = false;
                    this.success_message = false;
                    this.stake = 0;
                    this.posting_as_tip = false;
                    this.minimal_odds = 0;
                    this.second_odds = null;
                    this.bookmaker = PINNACLE_ID;
                    this.stake_units = null;
                    this.result = {};
                    this.analysis = '';
                    this.posting_in_competition = true;

                    this.url = new_url;
                    this.url_submit = submit_url;

                    var self = this;
                    $.get(this.url, function (result) {
                        self.result = result;
                        self.second_odds = result.tip.odds;
                        self.loaded = true;
                    }).fail(function (response) {
                        self.loaded = true;
                        self.error = response.responseJSON.error;
                    });
                },
                checkOdds: function () {
                    if (!this.url) {
                        return;
                    }

                    var self = this;

                    $.get(this.url, function (result) {
                        if (self.result.tip.odds != result.tip.odds) {
                            self.loaded = true;
                            self.url = null;
                            self.error = 'Selected odds are no longer available. Please try again.';
                        }
                    }).fail(function() {
                        self.loaded = true;
                        self.url = null;
                        self.error = 'Selected event is not longer available. Please try again'
                    })
                }
            },
            ready: function () {
                var self = this;
                setInterval(function () {
                    self.checkOdds();
                }, REFRESH_RATE);
            }
        })
    }

})();